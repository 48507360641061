import { LocaleEnum } from '@locales/types';
import IdBaseModel from '@models/base-model/id-base';
import WishlistItem from '@models/wishlist-item';
import { ImageUrl } from '@type/models';
import ConversionUtils from '@utils/conversion.utils';
import DateUtils from '@utils/date.utils';
import {
    DeliveryFrequencyOptionsTypeEnum,
    IComponentProductDto,
    InventoryStatusEnum,
    PaymentTypeEnum,
    PricePerMonth,
    SalesStatusEnum,
    SalesTypeEnum,
    SubscriptionDeliveryPeriodEnum,
    SubscriptionEndTypeEnum,
} from './responseDto/component-product.dto';
import SubscriptionInfo from './subscription-info';

/**
 * Channel component product
 * @link https://harmony-product-dev.travelflan.com/docs#/external-end-user%20%2F%20Product/read_list_of_component_products_api_v1_external_e_channels__channel_id__component_products_get
 */
class ComponentProduct extends IdBaseModel {
    protected _brand: string;
    protected _brandId: string | null;
    protected _title: string;
    protected _salesType: SalesTypeEnum;
    protected _salesStatus: SalesStatusEnum;
    protected _subscriptionDeliveryPeriod: SubscriptionDeliveryPeriodEnum;
    protected _subscriptionInfo: SubscriptionInfo | null;
    protected _minSellPrice: number;
    protected _minSellPriceBeforeReduction: number | null;
    protected _minSellPriceAfterReduction: number | null;
    protected _minSubPrice: PricePerMonth;
    protected _minSubMarketPrice: PricePerMonth;
    protected _minPurPrice: number;
    protected _marketPrice: number;
    protected _discountRate: number;
    protected _wishlistId: number | null;
    protected _images: ImageUrl[];
    protected _timesaleApplied: boolean;
    protected _timesaleAppliedNow: boolean;
    protected _inventoryStatus: InventoryStatusEnum | string;
    protected _deliveryDaysPerMonth: string[];
    protected _deliveryDaysPerWeek: string[];
    protected _minSubPricePerMonth: number | undefined;
    protected _minSubMarketPricePerMonth: number | undefined;
    protected _isAgeVerificationRequired: boolean;

    private readonly _WEEKS_IN_A_MONTH = 4;

    constructor(componentProductDto: IComponentProductDto) {
        super(componentProductDto.id);
        this._brand = componentProductDto.brand;
        this._brandId = componentProductDto.brandId;
        this._title = componentProductDto.title;
        this._salesType = componentProductDto.salesType;
        this._salesStatus = componentProductDto.salesStatus;
        this._subscriptionDeliveryPeriod = componentProductDto.subscriptionDeliveryPeriod;
        this._subscriptionInfo = componentProductDto.subscriptionInfo ? new SubscriptionInfo(componentProductDto.subscriptionInfo) : null;
        this._minSellPrice = componentProductDto.minSellPrice;
        this._minSellPriceBeforeReduction = componentProductDto.minSellPriceBeforeReduction;
        this._minSellPriceAfterReduction = componentProductDto.minSellPriceAfterReduction;
        this._minSubPrice = componentProductDto.minSubPrice;
        this._minSubMarketPrice = componentProductDto.minSubMarketPrice;
        this._minPurPrice = componentProductDto.minPurPrice;
        this._marketPrice = componentProductDto.marketPrice;
        this._discountRate = componentProductDto.discountRate;
        this._wishlistId = componentProductDto.wishlistId;
        this._images = componentProductDto.images;
        this._timesaleApplied = componentProductDto.timesaleApplied;
        this._timesaleAppliedNow = componentProductDto.timesaleAppliedNow;
        this._inventoryStatus = componentProductDto.inventoryStatus;
        this._deliveryDaysPerMonth = componentProductDto.deliveryDaysPerMonth && DateUtils.sortDateArray(componentProductDto.deliveryDaysPerMonth);
        this._deliveryDaysPerWeek = componentProductDto.deliveryDaysPerWeek;
        this._isAgeVerificationRequired = componentProductDto.hasAgeLimitation;
    }

    /**
     * converts WishlistItem to ComponentProduct for product card component
     * @param wishlistItem - The WishlistItem instance to convert
     * @returns The ComponentProduct DTO created from the WishlistItem
     */
    static createFromWishlistItem(wishlistItem: WishlistItem): ComponentProduct {
        const componentProductDto: IComponentProductDto = {
            id: wishlistItem.productId,
            brand: wishlistItem.brand,
            brandId: wishlistItem.brandId,
            title: wishlistItem.title,
            minSellPrice: wishlistItem.minSellPrice,
            minPurPrice: wishlistItem.minPurPrice,
            marketPrice: wishlistItem.marketPrice,
            discountRate: wishlistItem.discountRate,
            wishlistId: wishlistItem.wishlistId,
            images: [wishlistItem.image],
            timesaleAppliedNow: wishlistItem.timesaleAppliedNow,
            salesType: wishlistItem.salesType,
            subscriptionInfo: wishlistItem.subscriptionInfo,

            // following fields do not exist in WishlistItem and are filled with temp values
            minSellPriceBeforeReduction: wishlistItem.minSellPrice,
            minSellPriceAfterReduction: wishlistItem.minSellPrice,
            salesStatus: SalesStatusEnum.Selling,
            subscriptionDeliveryPeriod: SubscriptionDeliveryPeriodEnum.AnyDay,
            deliveryDaysPerWeek: [],
            deliveryDaysPerMonth: [],
            minSubPrice: [0, 0],
            minSubMarketPrice: [0, 0],
            timesaleApplied: false,
            inventoryStatus: '',
            hasAgeLimitation: false,
        };
        return new ComponentProduct(componentProductDto);
    }

    /**Additional ID field for extending common components */
    get productId(): string {
        return this._id;
    }

    get brand(): string {
        return this._brand;
    }

    get brandId(): string | null {
        return this._brandId;
    }

    get title(): string {
        return this._title;
    }

    get salesType(): SalesTypeEnum {
        return this._salesType;
    }

    get isUnavailable(): boolean {
        return this._salesStatus === SalesStatusEnum.Banned || this._salesStatus === SalesStatusEnum.Stopped;
    }

    get isNormalSalesType(): boolean {
        return this._salesType === SalesTypeEnum.Normal || this._salesType === SalesTypeEnum.Hybrid;
    }

    get isSubscriptionSalesType(): boolean {
        return this._salesType === SalesTypeEnum.Subscription || this._salesType === SalesTypeEnum.Hybrid;
    }

    get isAgeVerificationRequired(): boolean {
        return this._isAgeVerificationRequired;
    }

    get isHybridSalesType(): boolean {
        return this._salesType === SalesTypeEnum.Hybrid;
    }

    get subscriptionDeliveryPeriod(): SubscriptionDeliveryPeriodEnum {
        return this._subscriptionDeliveryPeriod;
    }

    get subscriptionInfo(): SubscriptionInfo | undefined {
        return this._subscriptionInfo || undefined;
    }

    get minSellPrice(): number {
        return this._minSellPrice;
    }

    get minSellPriceString(): string {
        return ConversionUtils.numberToString(this._minSellPrice);
    }

    get discountRate(): number {
        if (this.isSubscriptionSalesType && this._subscriptionInfo?.priceDisplayTypeIsMinSubPrice) return Math.ceil((1 - this._minSubPrice[0] / this._minSubMarketPrice[0]) * 100);
        return Math.ceil((1 - this.displayedSellPrice / this._marketPrice) * 100);
    }

    get hasDiscount(): boolean {
        return this.discountRate > 0;
    }

    get sellPrice(): number {
        return Math.floor(this._minSellPrice);
    }

    get sellPriceString(): string {
        return ConversionUtils.numberToString(this.sellPrice);
    }

    get marketPriceString(): string {
        if (this._marketPrice === this.minSellPrice) return '';
        return ConversionUtils.numberToString(Math.floor(this._marketPrice));
    }

    get wishlistId(): number | undefined {
        return this._wishlistId === null ? undefined : this._wishlistId;
    }

    /**
     * Returns the first image from the image list
     */
    get image(): ImageUrl | undefined {
        return this._images[0];
    }

    get images(): ImageUrl[] {
        return this._images;
    }

    get timesaleApplied(): boolean {
        return this._timesaleApplied;
    }

    get timesaleAppliedNow(): boolean {
        return this._timesaleAppliedNow;
    }

    get minSellPriceAfterReduction(): string {
        return ConversionUtils.numberToString(Math.floor(this._minSellPriceAfterReduction || this._minSellPrice));
    }

    get isSoldOut(): boolean {
        return this._inventoryStatus === InventoryStatusEnum.SoldOut;
    }

    get minSubPrice(): number {
        if (!this._minSubPrice) return this.minSellPrice;
        return this._minSubPrice[0];
    }

    get subscriptionPriceString(): string {
        if (!this._minSubPrice) return this.minSellPriceString;
        return ConversionUtils.numberToString(this._minSubPrice[0]);
    }

    get subscriptionMonthlyUnit(): number {
        if (!this._minSubPrice) return 0;
        return this._minSubPrice[1];
    }

    get subscriptionMonthlyUnitString(): string {
        if (!this._minSubPrice || this._minSubPrice[1] === 1) return '';
        return ConversionUtils.numberToString(this._minSubPrice[1]);
    }

    get subscriptionMarketPrice(): number | undefined {
        if (!this.isSubscriptionSalesType || !this._minSubMarketPrice || this._minSubMarketPrice?.[0] === this._minSubPrice?.[0]) return;
        return this._minSubMarketPrice[0];
    }

    get subscriptionMarketPriceString(): string | undefined {
        if (!this.subscriptionMarketPrice) return;
        return ConversionUtils.numberToString(this.subscriptionMarketPrice);
    }

    get minSubMarketPricePerMonthString(): string {
        if (this._minSubMarketPricePerMonth && this._minSubPricePerMonth && this._minSubMarketPricePerMonth <= this._minSubPricePerMonth) return '';
        const roundDownPrice = Math.floor(this._minSubMarketPricePerMonth || 0);
        return ConversionUtils.numberToString(roundDownPrice);
    }

    getNumberOfDeliveriesInOneMonth(deliveryFrequencyOptionsType: DeliveryFrequencyOptionsTypeEnum, deliveryFrequency: number): number {
        if (deliveryFrequencyOptionsType === DeliveryFrequencyOptionsTypeEnum.OnceEveryNMonths) return 1;
        return this._WEEKS_IN_A_MONTH / deliveryFrequency;
    }

    get deliveryDaysPerMonth(): string[] {
        return this._deliveryDaysPerMonth;
    }

    get deliveryDaysPerWeek(): string[] {
        if (!this._deliveryDaysPerWeek) return [];
        return DateUtils.sortWeekdays(this._deliveryDaysPerWeek);
    }

    get deliveryDaysPerWeekString(): string {
        return this.deliveryDaysPerWeek?.join(' / ');
    }

    get estimatedDeliveryDaysPerWeekString(): string {
        if (!this._deliveryDaysPerWeek) return '';
        return DateUtils.shippingDateToEstimatedDeliveryDate(3, this._deliveryDaysPerWeek).join(' / ');
    }

    get isDeliveryPeriodAnyDay(): boolean {
        return this._subscriptionDeliveryPeriod === SubscriptionDeliveryPeriodEnum.AnyDay;
    }

    get isDeliveryCertainDaysPerWeek(): boolean {
        return this._subscriptionDeliveryPeriod === SubscriptionDeliveryPeriodEnum.CertainDaysPerWeek;
    }

    get isDeliveryCertainDaysPerMonth(): boolean {
        return this._subscriptionDeliveryPeriod === SubscriptionDeliveryPeriodEnum.CertainDaysPerMonth;
    }

    get isSubscriptionEndTypeIndefinite(): boolean {
        return this._subscriptionInfo?.subscriptionEndType === SubscriptionEndTypeEnum.Indefinite;
    }

    get isSubscriptionEndTypeByDate(): boolean {
        return this._subscriptionInfo?.subscriptionEndType === SubscriptionEndTypeEnum.ByDate;
    }

    get isSubscriptionEndTypeByRound(): boolean {
        return this._subscriptionInfo?.subscriptionEndType === SubscriptionEndTypeEnum.ByRound;
    }

    get hasMultipleMonthlyPaymentFrequencies(): boolean {
        return this._subscriptionInfo?.paymentType === PaymentTypeEnum.PaymentPerNMonths && this._subscriptionInfo.perMonthPaymentRuleSettings.length > 1;
    }

    /** The market price actually displayed in the component. */
    get displayedMarketPriceString(): string {
        if (this.isSubscriptionSalesType && this._subscriptionInfo?.priceDisplayTypeIsMinSubPrice) return this.subscriptionMarketPriceString || '';
        return this.marketPriceString;
    }

    get displayedSellPrice(): number {
        if (this.timesaleAppliedNow) {
            return this._minSellPriceAfterReduction || this._minSellPrice;
        }
        if (this.isSubscriptionSalesType && this._subscriptionInfo?.priceDisplayTypeIsMinSubPrice) return this.minSubPrice;
        if (this.isSubscriptionSalesType && this._subscriptionInfo?.priceDisplayTypeIsMinPurPrice) return this.minPurPrice;

        return this._minSellPrice;
    }

    /** The sell price actually displayed in the component. */
    get displayedSellPriceString(): string {
        if (this.timesaleAppliedNow) {
            return this.minSellPriceAfterReduction;
        }
        if (this.isSubscriptionSalesType && this._subscriptionInfo?.priceDisplayTypeIsMinSubPrice) return this.subscriptionPriceString;
        if (this.isSubscriptionSalesType && this._subscriptionInfo?.priceDisplayTypeIsMinPurPrice) return this.minPurPriceString;

        return this.minSellPriceString;
    }

    /** 결제주기할인 적용 여부 */
    get isDiscountPerPaymentIntervalApplied(): boolean {
        return this.subscriptionInfo?.discountPerPaymentRuleSettings.some((discount) => discount !== 0) || false;
    }

    deliveryDaysPerMonthString(locale?: string): string {
        if (!this._deliveryDaysPerMonth) return '';
        if (locale === LocaleEnum.ko) {
            const index = this._deliveryDaysPerMonth.findIndex((date) => date === '28일');
            if (index !== -1) {
                this._deliveryDaysPerMonth[index] = '말일';
            }
        }
        return this._deliveryDaysPerMonth.join(' / ');
    }

    get minPurPrice(): number {
        return this._minPurPrice;
    }

    get minPurPriceString(): string {
        return ConversionUtils.numberToString(this._minPurPrice);
    }

    get showBrandOnProductCard(): boolean {
        return !!this._brand && !!this._brandId;
    }
}

export default ComponentProduct;
