import CacheKeys from '@api/cache-keys';
import { IQueryCouponProductsDto } from '@models/product/requestDto/query-coupon-products.dto';
import { ProductQuery, RecommendedType } from '@type/api';
import { CouponId, InquiryId, ProductId } from '@type/models';

abstract class ProductCacheKeys extends CacheKeys {
    static recommendedProductsCacheKey(id: ProductId, type: RecommendedType): string {
        return `/products/${id}/${type}-recommended-products`;
    }

    static inquiryAnswersCacheKey(id: InquiryId): string {
        return `/inquiry_answers/inquiry/${id}`;
    }

    static myInquiryAnswersCacheKey(): string {
        return `/inquiries`;
    }

    static productCacheKey(id: ProductId): string {
        return `/products/${id}`;
    }

    static productSkusCacheKey(productId: ProductId): string {
        return `/product/${productId}/skus`;
    }

    static componentProductsCacheKey(productIds?: ProductId[]): string {
        return `/component-products/${productIds?.join('/') || ''}`;
    }

    static productInquiriesCacheKey(productId: ProductId): string {
        return `/products/${productId}/inquiries`;
    }

    static productsCacheKey(query: ProductQuery): string {
        return `/products/${Object.values(query).join(',')}`;
    }

    static brandCategoryIds(brandId: string): string {
        return `/brands/${brandId}/categories`;
    }

    static productsByCouponIdCacheKey(couponId: CouponId, query: IQueryCouponProductsDto): string {
        return `products/coupons/${couponId}/component-products/${Object.values(query).join('/')}`;
    }

    static orderLimitCacheKey(productId: string): string {
        return `/products/${productId}/order-limit`;
    }
}

export default ProductCacheKeys;
