import ApiService from '@api/api.service';
import { authClient } from '@api/auth/auth.client';
import { IOrderLimitDto } from '@models/order-limit/responseDto/order-limit.dto';
import { ICreateProductInquiryDto } from '@models/product-inquiry-collection/requestDto/create-inquiry.dto';
import { IQueryProductInquiryDto } from '@models/product-inquiry-collection/requestDto/query-product-inquiry.dto';
import { IUpdateProductInquiryDto } from '@models/product-inquiry-collection/requestDto/update-inquiry.dto';
import { IInquiryProductAnswerDto } from '@models/product-inquiry-collection/responseDto/inquiry-answer.dto';
import { IProductInquiryCollectionDto } from '@models/product-inquiry-collection/responseDto/inquiry-collection.dto';
import { IProductInquiryDto } from '@models/product-inquiry-collection/responseDto/inquiry.dto';
import { IQueryComponentProductDto } from '@models/product/requestDto/query-component-product.dto';
import { IQueryCouponProductsDto } from '@models/product/requestDto/query-coupon-products.dto';
import { IComponentProductDto, SalesTypeEnum } from '@models/product/responseDto/component-product.dto';
import { IListProductDto } from '@models/product/responseDto/list-product.dto';
import { IProductDto } from '@models/product/responseDto/product.dto';
import { ILeanSkuDto } from '@models/sku/responseDto/lean-sku.dto';
import { IWishlistItemDto } from '@models/wishlist-item/responseDto/wishlist-item.dto';
import { PaginatedQuery, ProductQuery, RecommendedType } from '@type/api';
import { CouponId, InquiryId, PaginatedResults, ProductId } from '@type/models';
import { SortProductByEnum } from '@type/product';
import EnvironmentUtils from '@utils/environment.utils';
import snakecaseKeys from 'snakecase-keys';
import ProductCacheKeys from './cache-keys';

/**
 * Api client handling product related logic
 * @link https://api-product-dev.harmonycvm.com/docs#/
 */
class ProductClient extends ProductCacheKeys {
    private static readonly PRODUCT_API_PREFIX = '/api/v1/external/e/channels/';
    private static readonly _client = new ApiService(`https://api-product${EnvironmentUtils.API_ENV}.harmonycvm.com`, authClient);

    static async getProductById(id: ProductId): Promise<IProductDto> {
        return this._client.get<IProductDto>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/products/${id}`);
    }

    /**
     * Returns similar recommended products based on a particular product.
     */
    static async getRecommendedProductsById(id: ProductId, type: RecommendedType, salesType: SalesTypeEnum): Promise<IComponentProductDto[]> {
        return this._client.get<IComponentProductDto[]>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/products/${id}/${type}-recommended-products`, {
            params: {
                sales_type: salesType,
            },
        });
    }

    static async getProducts(query: ProductQuery): Promise<PaginatedResults<IListProductDto>> {
        return this._client.get<PaginatedResults<IListProductDto>>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/products`, {
            params: snakecaseKeys({
                ...query,
                itemsPerPage: query.itemsPerPage || 10,
            }),
        });
    }

    /**
     * Returns array of component products based on an array of ids
     */
    static async postComponentProducts(query?: IQueryComponentProductDto): Promise<PaginatedResults<IComponentProductDto>> {
        return this._client.post<PaginatedResults<IComponentProductDto>>(
            `${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/component-products`,
            {
                ...query,
                items_per_page: query?.itemsPerPage || 20,
                page: query?.page || 1,
                product_ids: query?.productIds,
            },
            {
                params: {
                    sales_type: query?.salesType,
                    display_out_of_stock: query?.displayOutOfStock || false,
                },
            }
        );
    }

    static async getProductsByCouponId(
        couponId: CouponId,
        { itemsPerPage = 20, page = 1, couponApplicableBrandIds, couponApplicableCategoryIds, ...query }: IQueryCouponProductsDto = {}
    ): Promise<PaginatedResults<IComponentProductDto>> {
        return this._client.post<PaginatedResults<IComponentProductDto>>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/coupons/${couponId}/component-products`, {
            itemsPerPage,
            page,
            couponApplicableBrandIds: couponApplicableBrandIds || [],
            couponApplicableCategoryIds: couponApplicableCategoryIds || [],
            ...query,
        });
    }

    /**
     * Increases the total view count for the product.
     */
    static async patchIncreaseProductView(id: ProductId): Promise<void> {
        return this._client.patch<void>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/products/${id}/increase-view-count`);
    }

    /**
     * Gets all product inquiries (logged in users)
     */
    static async getMyInquiries(options?: IQueryProductInquiryDto): Promise<IProductInquiryCollectionDto> {
        return this._client.get<IProductInquiryCollectionDto>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries`, {
            params: snakecaseKeys({
                page: options?.page || 1,
                itemsPerPage: options?.itemsPerPage || 10,
                status: options?.status,
                // ordering: '-create_dt'
            }),
        });
    }

    static async getInquiries(productId: ProductId, options?: PaginatedQuery): Promise<IProductInquiryCollectionDto> {
        return this._client.get<IProductInquiryCollectionDto>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/products/${productId}/inquiries`, {
            params: snakecaseKeys({
                page: options?.page || 1,
                itemsPerPage: options?.itemsPerPage || 10,
            }),
        });
    }

    static async getInquiryAnswers(inquiryId: InquiryId): Promise<IInquiryProductAnswerDto[]> {
        return this._client.get<IInquiryProductAnswerDto[]>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiry_answers/inquiry/${inquiryId}`);
    }

    /**
     * Post new product inquiry
     * TODO: post request currently failing due to body schema, error unclear
     */
    static async postInquiry(createInquiryDto: ICreateProductInquiryDto): Promise<IProductInquiryDto> {
        return this._client.post<IProductInquiryDto>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries`, createInquiryDto);
    }

    /**
     * Updates product inquiry
     * TODO: post request currently failing due to body schema, error unclear
     */
    static async putInquiry(updateInquiryDto: IUpdateProductInquiryDto, inquiryId: string): Promise<IProductInquiryDto> {
        return this._client.put<IProductInquiryDto>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries/${inquiryId}`, updateInquiryDto);
    }

    /**
     * Deletes a product inquiry
     */
    static async deleteInquiry(dataId: string): Promise<void> {
        return this._client.delete<void>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/inquiries/${dataId}`);
    }

    /**
     * Returns all wishlist items
     */
    static async getWishlist(query: PaginatedQuery, ordering?: SortProductByEnum): Promise<PaginatedResults<IWishlistItemDto>> {
        return this._client.get<PaginatedResults<IWishlistItemDto>>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/wishlist`, {
            params: snakecaseKeys({
                itemsPerPage: query?.itemsPerPage,
                page: query?.page,
                ordering,
            }),
        });
    }

    /**
     * Adds product to wishlist
     */
    static async addToWishlist(productId: ProductId): Promise<IWishlistItemDto> {
        return this._client.post(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/wishlist/${productId}`, {});
    }

    /**
     * Adds many product to wishlist
     */
    static async addManyToWishlist(productIds: ProductId[]): Promise<void> {
        try {
            await Promise.all(productIds.map((id) => this.addToWishlist(id)));
            return;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Removes product from wishlist
     */
    static async deleteFromWishlist(wishlistId: number): Promise<void> {
        return this._client.delete(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/wishlist/${wishlistId}`);
    }

    static async getProductSkus(productId: ProductId): Promise<ILeanSkuDto[]> {
        try {
            const skuData = await this._client.get<{ skus: ILeanSkuDto[] }>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/product/${productId}/skus`, {
                params: {
                    channel_id: EnvironmentUtils.CHANNEL_ID,
                    product_id: productId,
                },
            });
            return skuData.skus;
        } catch (error) {
            throw error;
        }
    }

    static async getBrandCategoryIds(brandId: string): Promise<{ ids: string[] }> {
        return this._client.get<{ ids: string[] }>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/products/brands/${brandId}/categories`);
    }

    static async getOrderLimit(productId: string) {
        return this._client.get<IOrderLimitDto>(`${this.PRODUCT_API_PREFIX}${EnvironmentUtils.CHANNEL_ID}/products/${productId}/order-limit`);
    }
}

export { ProductClient };
